import React from "react"

const Footer = () => {
  return (
    <div className="max-w-7xl mx-auto mt-10 px-4">
      <div className="grid grid-cols-2 xxs:grid-cols-1 lg:grid-cols-2">
        <div>
          <h4 className="text-white font-poppins font-bold text-6xl xxs:text-lg xs:text-xl sm:text-xl lg:text-6xl">
            RE
            <span class="text-gradient palette-red">VENTURE</span>
          </h4>
          <p className="text-white text-sm mt-5 opacity-50">
            Impulsione suas vendas com um CLICK inteligente!
          </p>
          <p className="text-white text-sm mt-5 opacity-50">
            Contatos: <br />
            Brasil: <a href="tel:+5531999227711">31 99922-7711</a> <br />
            Portugal: <a href="tel:+351912874191">912 874 191</a>
          </p>
          <h1 className="text-white text-lg mt-20 ">
            © copyright <a href="#">reventure.digital</a>
          </h1>
        </div>
      </div>
    </div>
  )
}

export default Footer
